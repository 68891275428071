import React, {useState} from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';

import OutlinedInput from "../Shared/OutlinedInput"
import Button from "@mui/material/Button"
import DividerSocialBtn from "../Shared/DividerSocialBtn/DividerSocialBtn";
import CheckboxLabel from "../Shared/CheckboxLabel/CheckboxLabel";
import Copyright from "../Shared/Copyright/Copyright";

import { tss } from 'tss-react';

export default function SignUp(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();
  const [params, setParams] = useState(props.user);
  const [errors, setErrors] = useState(props.user.errors);
  const error = props.flash.alert;

  const handleChange = param => val => {
    const allParams = {...params}
    setParams({ ...allParams, [param]: val });
    setErrors({ ...errors, [param]: null });
  };

  const formAttrs = {
    // action: RailsRoutes.user_registration_path(),
    method: 'post',
    className: classes.tagForm
  }

  const termLink = (item) => {
    return <Link rel="stylesheet" href={item.url} target="_blank" key={item.title}>{item.title}</Link>
  }

  const oxfordTermLinks = (linksArray) => {
    if (linksArray.length === 1) {
      return termLink(linksArray[0]);
    } else if (linksArray.length === 2) {
      return [termLink(linksArray[0]), ' and ', termLink(linksArray[1])];
    } else {
      const links = linksArray.map((item, index) => {
        if (index === 0) {
          return [termLink(item)];
        } else if (index === linksArray.length - 1) {
          return [' and ', termLink(item)];
        } else {
          return [', ', termLink(item)];
        }
      }).flat();
      return links.flat();
    }
  }

  const terms_label = (
    <span>
      I agree with {oxfordTermLinks(theme.variables.termsAndConditionsList)}.
    </span>
  )

  const newsletter_label = (
    <span>
      I want to receive newsletters from {theme.variables.providerName}.
    </span>
  )

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Already have an account? <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div>
              <div className={classes.tagForm}>
                <div className={classes.root}>
                  <div className={classes.loginForm}>
                    <span className={classes.stepText}>Step 1 of 2</span>
                    <h3 className="textStylesh2Default">Let's get started</h3>
                    {error && (
                      <span className={classes.errorBlockBig}>
                        {error}
                      </span>
                    )}
                    <OutlinedInput
                      customClass={classes.textField}
                      name="user[first_name]"
                      placeholder="First name"
                      value={params.first_name}
                      onInputComplete={handleChange( "first_name" )}
                      error={errors.first_name}
                      label="First name"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="given-name"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="user[last_name]"
                      placeholder="Last name"
                      value={params.last_name}
                      onInputComplete={handleChange( "last_name" )}
                      error={errors.last_name}
                      label="Last name"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="family-name"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="user[email]"
                      placeholder="Email"
                      value={params.email}
                      onInputComplete={handleChange( "email" )}
                      error={errors.email}
                      label="Email"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="email"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="user[password]"
                      placeholder="Password"
                      onInputComplete={handleChange( "password" )}
                      error={errors.password}
                      label="Password"
                      type="password"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="new-password"/>
                    <CheckboxLabel
                      checked={params.terms_and_conditions}
                      name="user[terms_and_conditions]"
                      label={terms_label}
                      value="1"
                      onChange={handleChange( "terms_and_conditions" )}/> {
                      errors.terms_and_conditions && ( <span className={classes.errorBlock}>
                        {errors.terms_and_conditions}
                      </span> )
                    }
                    <CheckboxLabel
                      checked={params.newsletter}
                      name="user[newsletter]"
                      label={newsletter_label}
                      onChange={handleChange( "newsletter" )}/>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.fullWidth}>
                      Continue with email
                    </Button>
                    <DividerSocialBtn text="or continue with"/>
                  </div>
                </div>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
