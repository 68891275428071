import React, {useState} from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';

import OutlinedInput from "../Shared/OutlinedInput"
import Button from "@mui/material/Button"
import DividerSocialBtn from "../Shared/DividerSocialBtn/DividerSocialBtn";
import CheckboxLabel from "../Shared/CheckboxLabel/CheckboxLabel";
import Copyright from "../Shared/Copyright/Copyright";

import { tss } from 'tss-react';
import WebsaSelect from "../Shared/WebsaSelect";
import _ from "lodash";

import countryList from 'react-select-country-list';

import ReCAPTCHA from "react-google-recaptcha";

export default function RegistrationForm(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();
  const [params, setParams] = useState(props.form);
  const [errors, setErrors] = useState(props.form.errors);
  const error = props.flash.alert;

  const countryOptions = countryList().getData().filter(item => item.value !== 'RU' && item.value !== 'BY');

  const productOptions = theme.variables.registrationProductOptions.map(item => ({ value: item, label: item }));

  const recaptchaKey = props.recaptcha_key;

  const handleChange = param => val => {
    const allParams = {...params}
    setParams({ ...allParams, [param]: val });
    setErrors({ ...errors, [param]: null });
  };

  const formRef = React.useRef();
  const recaptchaRef = React.useRef();

  const sendRecaptchaRequest = (e) => {
    e.preventDefault(); // form will be submitted by recaptcha callback

    recaptchaRef.current.execute();
  }

  const handleRecaptcha = (value) => {
    formRef.current.submit();
  }

  const formAttrs = {
    action: RailsRoutes.registration_path(),
    method: 'post',
    className: classes.tagForm,
    ref: formRef,
    onSubmit: sendRecaptchaRequest
  }

  const termLink = (item) => {
    return <Link rel="stylesheet" href={item.url} target="_blank" key={item.title}>{item.title}</Link>
  }

  const oxfordTermLinks = (linksArray) => {
    if (linksArray.length === 1) {
      return termLink(linksArray[0]);
    } else if (linksArray.length === 2) {
      return [termLink(linksArray[0]), ' and ', termLink(linksArray[1])];
    } else {
      const links = linksArray.map((item, index) => {
        if (index === 0) {
          return [termLink(item)];
        } else if (index === linksArray.length - 1) {
          return [' and ', termLink(item)];
        } else {
          return [', ', termLink(item)];
        }
      }).flat();
      return links.flat();
    }
  }

  const terms_label = (
    <span>
      I agree with {oxfordTermLinks(theme.variables.termsAndConditionsList)}.
    </span>
  )

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Already have an account? <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div>
              <div className={classes.tagForm}>
                <div className={classes.root}>
                  <div className={classes.regForm}>
                    <h3 className="textStylesh2Default">Let's get started</h3>
                    {error && (
                      <span className={classes.errorBlockBig}>
                        {error}
                      </span>
                    )}
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[first_name]"
                      placeholder="First name"
                      value={params.first_name}
                      onInputComplete={handleChange("first_name")}
                      error={errors.first_name}
                      label="First name"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="given-name"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[last_name]"
                      placeholder="Last name"
                      value={params.last_name}
                      onInputComplete={handleChange("last_name")}
                      error={errors.last_name}
                      label="Last name"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="family-name"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[email]"
                      placeholder="Email"
                      value={params.email}
                      onInputComplete={handleChange("email")}
                      error={errors.email}
                      label="Email"
                      isUncontroled="isUncontroled"
                      noAdditionalBtn
                      autoComplete="email"/>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[company]"
                      placeholder="Company name"
                      value={params.company}
                      onChange={handleChange("company")}
                      error={errors.company}
                      label="Company name"
                      isUncontroled
                      noAdditionalBtn
                    />
                    <span className={classes.signature}>Optional</span>
                    <WebsaSelect
                      name="form[country_code]"
                      width={450}
                      menuHeight={196}
                      options={countryOptions}
                      placeholder="Country"
                      label
                      isSearchable
                      value={_.find(countryOptions, {value: params.country_code})}
                      onChange={handleChange("country_code")}
                    />
                    <span className={classes.signature}>Country choice will affect your products prices</span>
                    <WebsaSelect
                      name="form[products][]"
                      width={450}
                      menuHeight={196}
                      options={productOptions}
                      placeholder="Areas of interest"
                      label
                      isSearchable
                      multiple
                      value={_.find(productOptions, {value: params.products})}
                      onChange={handleChange("products")}
                    />
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[website]"
                      placeholder="Website"
                      value={params.website}
                      onChange={handleChange("website")}
                      error={errors.website}
                      label="Website"
                      isUncontroled
                      noAdditionalBtn
                    />
                    <span className={classes.signature}>Optional</span>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[phone]"
                      placeholder="Phone"
                      value={params.phone}
                      onChange={handleChange("phone")}
                      error={errors.phone}
                      label="Phone"
                      isUncontroled
                      noAdditionalBtn
                    />
                    <span className={classes.signature}>Optional</span>
                    <OutlinedInput
                      customClass={classes.textField}
                      name="form[note]"
                      placeholder="Tell us more about your request..."
                      value={params.note}
                      onChange={handleChange("note")}
                      error={errors.note}
                      label="Tell us more about your request..."
                      rows={4}
                      isUncontroled
                      noAdditionalBtn
                      multiline
                    />
                    <span className={classes.signature}>Optional</span>
                    <CheckboxLabel
                      checked={params.terms_and_conditions}
                      name="form[terms_and_conditions]"
                      label={terms_label}
                      value="1"
                      onChange={handleChange("terms_and_conditions")}/> {
                    errors.terms_and_conditions && (<span className={classes.errorBlock}>
                            {errors.terms_and_conditions}
                          </span>)
                  }
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={recaptchaKey}
                      size="invisible"
                      onChange={handleRecaptcha}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.fullWidth}>
                      Send message
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
