import Button from "@mui/material/Button";
import React, {useState} from "react";

import styles from "./styles";
import OutlinedInput from "../Shared/OutlinedInput";
import Link from "@mui/material/Link";
import DividerSocialBtn from "../Shared/DividerSocialBtn/DividerSocialBtn";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';
import Copyright from "../Shared/Copyright/Copyright";

import { tss } from 'tss-react';

export default function Login(props) {
  const theme = ThemeProvider(props.theme);
  const { classes } = tss.create(styles(theme))();

  const error = props.flash.alert;

  const [params, setParams] = useState({});
  const [errors, setErrors] = useState({});

  const nonEmptyFields = ['email', 'password'];

  const emptyErrorValue = param => param + " can't be blank";

  const validateNonEmpty = function(event) {
    let hasErrors = false;
    nonEmptyFields.forEach(elem => {
      if (!params[elem]) {
        setErrors(errors => { return {...errors, [elem]: emptyErrorValue(elem)} });
        hasErrors = true
      }
    });
    if (hasErrors) {
      event.preventDefault();
    }
  }

  const formAttrs = {
    action: RailsRoutes.user_session_path(),
    method: 'post',
    className: classes.tagForm,
    onSubmit: validateNonEmpty
  }

  const handleChange = param => val => {
    setParams({...params, [param]: val})
    setErrors({...errors, [param]: null})
  };

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            {theme.configuration.registrationEnabled && (
              <p>
                Don’t have an account? <Link rel="stylesheet" href={theme.configuration.registrationUrl}>Contact sales</Link> to sign up.
              </p>
            )}
          </div>
          <RailsForm attrs={formAttrs}>
            <div className={classes.root}>
              <div className={classes.loginForm}>
                <h3 className="textStylesh2Default">Sign in to account</h3>
                {error && (
                  <span className={classes.errorBlockBig}>
                    {error}
                  </span>
                )}
                <OutlinedInput
                  customClass={classes.textField}
                  name="user[email]"
                  placeholder="Email"
                  label="Email"
                  isUncontroled
                  noAdditionalBtn
                  onChange={handleChange( "email" )}
                  error={errors.email}
                  autoComplete="email"
                />
                {theme.configuration.passwordRecoveryEnabled && (
                  <div className={classes.forgotContainer}>
                    <Link className={classes.forgot} rel="stylesheet" href={RailsRoutes.new_user_password_path()} tabIndex={-1}>
                      Forgot password?
                    </Link>
                  </div>
                )}
                <OutlinedInput
                  customClass={classes.textField}
                  name="user[password]"
                  placeholder="Password"
                  label="Password"
                  type="password"
                  isUncontroled
                  noAdditionalBtn
                  onChange={handleChange( "password" )}
                  error={errors.password}
                  autoComplete="current-password"
                />
                <Button variant="contained" type="submit" color="primary" className={classes.fullWidth}>
                  {theme.configuration.socialAuthEnabled ? 'Sign in with email' : 'Sign in'}
                </Button>
                <DividerSocialBtn text="or sign in with"/>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
