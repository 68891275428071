import colors from "./colors";

import checkboxLabel from "./components/checkboxLabel";
import copyright from "./components/copyright";
import dividerSocialBtn from "./components/dividerSocialBtn";
import outlinedInput from "./components/outlinedInput";
import slider from "./components/slider";
import websaSelect from "./components/websaSelect";

export default {
  checkboxLabel: checkboxLabel,
  copyright: copyright,
  dividerSocialBtn: dividerSocialBtn,
  outlinedInput: outlinedInput,
  slider: slider,
  websaSelect: websaSelect
};
